/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { CustomerConsentRequest } from '~/types/integrations/cia/event/consent/CustomerConsentRequest';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { ConsentProps } from '~/types/integrations/cia/event/consent/ConsentProps';

export default (instance: CiaIntegration) => {
  const { axios } = instance.services;
  const { locale } = instance.state;

  return (emailToken: string = ''): Promise<AxiosResponse> => {
    const consents: Array<keyof ConsentProps> = [
      'newsletter',
      'sms',
      'phone',
      'soft_newsletter',
      'soft_phone',
      'soft_sms',
    ];

    const payload: CustomerConsentRequest = {
      locale: locale.value,
      consents,
    };

    if (emailToken) {
      payload.email_token = emailToken;
    }

    return axios.post('customer-consent', payload);
  };
};
