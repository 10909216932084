import { isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { ViewCategoryRequest } from '~/types/integrations/cia/event/view-category/ViewCategoryRequest';
import { ViewCategoryProps } from '~/types/integrations/cia/event/view-category/ViewCategoryProps';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { email, locale, userId } = instance.state;

  // send view_category event to Bloomreach microservice - designed to run it on both ssr and csr
  return (slug: string, listedProducts: Array<string>, additionalProps?: ViewCategoryProps): Promise<AxiosResponse> => {
    if (!slug) {
      return;
    }

    const payload: ViewCategoryRequest = {
      locale: locale.value,
      slug,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      additional_props: {
        category_listed_products: listedProducts,
      },
    };

    if (email.value) {
      payload.email = email.value;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    if (!isEmpty(additionalProps)) {
      payload.additional_props = {
        ...payload.additional_props,
        ...additionalProps,
      };
    }
    return axios.post('view-category', payload);
  };
};
