import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { NuxtCookies } from 'cookie-universal-nuxt';
import { ssrRef } from '@nuxtjs/composition-api';
import type { IVueI18n } from 'vue-i18n';
import viewItem from './event/tracking/view-item';
import viewCategory from './event/tracking/view-category';
import resetPassword from './event/customer/reset-password';
import cartUpdate from './event/tracking/cart-update';
import customerUpdate from './event/customer/customer-update';
import consent from './event/tracking/consent';
import customerConsent from './event/customer/consent';
import newsletterDOI from './event/tracking/newsletter-doi';
import checkout from './event/tracking/checkout';
import recommendation from './event/tracking/recommendation';
import setEmail from './mutations/set-email';
import setUserId from './mutations/set-user-id';
import setCartId from './mutations/set-cart-id';
import initTracker from './util/initTracker';
import { JS_SDK_CONFIG } from '~/constants/bloomreach';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { i18nToLanguageAndCountry } from '~/helpers/locales/i18nCurrentDetails';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';

export default (
  $axios: NuxtAxiosInstance,
  $cookies: NuxtCookies,
  req: Context['req'],
  jsSdkPublicKey: String,
  i18n: IVueI18n,
) => {
  const axios = $axios.create({
    ...getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + 'cia'),
    headers: (process?.server
      ? { // on SSR these are not available by default, have to map them from original request
        referer: req.headers.referer ?? '',
        origin: req.headers.host ?? '',
      }
      : {}),
  });

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const $cia: CiaIntegration = {
    services: {
      axios,
      cookies: $cookies,
      req,
    },
    state: {
      email: ssrRef('', 'cia-email'),
      userId: ssrRef('', 'cia-user-id'),
      cartId: ssrRef('', 'cia-cart-id'),
      locale: ssrRef(i18nToLanguageAndCountry(i18n), 'cia-locale'),
    },
  } as CiaIntegration;

  $cia.mutations = {
    setEmail: setEmail($cia),
    setUserId: setUserId($cia),
    setCartId: setCartId($cia),
  };

  $cia.util = {
    initTracker: () => initTracker(jsSdkPublicKey, JS_SDK_CONFIG),
  };

  $cia.event = {
    viewItem: viewItem($cia),
    viewCategory: viewCategory($cia),
    cartUpdate: cartUpdate($cia),
    customerUpdate: customerUpdate($cia),
    consent: consent($cia),
    customerConsent: customerConsent($cia),
    newsletterDOI: newsletterDOI($cia),
    checkout: checkout($cia),
    resetPassword: resetPassword($cia),
    recommendation: recommendation($cia),
  };

  return $cia;
};
