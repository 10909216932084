import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { CustomerResetPasswordRequest }
  from '~/types/integrations/cia/event/reset-password/CustomerResetPasswordRequest';
import { RESET_PASSWORD } from '~/constants/cia';

export default (instance: CiaIntegration) => {
  const { axios } = instance.services;

  // send reset-password event to Bloomreach microservice
  return (email: string): Promise<AxiosResponse> => {
    const { locale } = instance.state;

    if (!email) {
      return;
    }

    const payload: CustomerResetPasswordRequest = {
      email,
      locale: locale.value,
    };

    return axios.post(RESET_PASSWORD, payload);
  };
};
