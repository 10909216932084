import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import type { NewsletterDoiRequest } from '~/types/integrations/cia/event/newsletter-doi/NewsletterDoiRequest';

export default (instance: CiaIntegration) => {
  const { axios } = instance.services;
  const { locale, userId } = instance.state;

  // send newsletter-doi event to Bloomreach microservice - designed to run it on both ssr and csr
  // newsletter-doi stands for newsletter double opt-in
  return (email: string): Promise<AxiosResponse> => {
    if (!email) {
      return;
    }

    const payload: NewsletterDoiRequest = {
      locale: locale.value,
      email,
    };

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('newsletter-doi', payload);
  };
};
