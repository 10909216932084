import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { CheckoutRequest } from '~/types/integrations/cia/event/checkout/CheckoutRequest';
import { BLOOMREACH_COOKIE, CHECKOUT_STEPS } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { email, cartId, locale, userId } = instance.state;

  // send checkout event to Bloomreach microservice - designed to run it on both ssr and csr
  return (step: number): Promise<AxiosResponse<any>>|void => {
    if (!cartId.value || !Object.values(CHECKOUT_STEPS).includes(step)) {
      return;
    }

    const payload: CheckoutRequest = {
      locale: locale.value,
      cart_id: cartId.value,
      step,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
    };

    if (email.value) {
      payload.email = email.value;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('checkout', payload);
  };
};
