import { AxiosResponse } from 'axios';
import { RecommendationEvent } from '~/types/integrations/cia/event/recommendation/Recommendation';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';
import { RecommendationAction } from '~/constants/recommendationRequest';
import { BloomreachEventName, BloomreachTypeId } from '~/types/localization/localizationHelperTypes';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { locale } = instance.state;
  return ({
    recommendationId,
    recommendationName,
    path,
    action,
  }: {
    recommendationId: BloomreachTypeId;
    recommendationName: BloomreachEventName;
    path?: string;
    action: RecommendationAction ;
  }): Promise<AxiosResponse> => {
    const payload: RecommendationEvent = {
      locale: locale.value,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      path,
      recommendation_id: recommendationId,
      recommendation_name: recommendationName,
      action,
    };
    return axios.post('recommendation', payload);
  };
};
