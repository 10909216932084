





























































































































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { cartGetters, useUser } from '@vsf-enterprise/commercetools';
import { onSSR, useVSFContext } from '@vue-storefront/core';
import { CT_COOKIE_NAME } from '@vsf-enterprise/commercetools-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { HitResultItem } from 'vue-instantsearch';
import SearchBar from '~/components/molecules/SearchBar.vue';
import NavMainBar from '~/components/molecules/Navigation/NavMainBar/NavMainBar.vue';
import TopMenuLanguageSelector from '~/components/molecules/TopMenuLanguageSelector.vue';
import { createUserCookie } from '~/api/auth';
import {
  // useUiState,
  useNavigationState,
  useUserExtended,
  useRouteExtended,
  useCartExtended,
  useI18n,
  useAlgolia,
  useAlgoliaGtmEvents,
  useIsPage,
  useCartSidebars,
  useCartSidebarStore,
  useIsMounted,
  useIntegrations,
  useHint,
} from '~/composables';
import CartIcon from '~/components/atoms/CartIcon.vue';
import { ROUTES } from '~/constants/routes';
import MyAccountDropdown from '~/components/molecules/MyAccountDropdown.vue';
import startDatadogUser from '~/helpers/logging/startDatadogUser';
import { SCHEMA_ITEM_TYPE } from '~/constants/seo';
import { i18nToLanguageAndCountry } from '~/helpers/locales/i18nCurrentDetails';
import { EXPONDO_LOGO_DARK_PATH, EXPONDO_LOGO_DARK_SIMPLE_PATH } from '~/constants/expondoLogoPath';
import { useMyOrders } from '~/composables/useMyOrders';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';
import SalesforceWebform from '~/components/organisms/SalesforceWebform/SalesforceWebform.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import Hint from '~/components/atoms/Hint/Hint.vue';
import NavMainBarAllCategoriesButton from
  '~/components/molecules/Navigation/NavMainBar/NavMainBarAllCategoriesButton/NavMainBarAllCategoriesButton.vue';

export default defineComponent({
  components: {
    MyAccountDropdown,
    CartIcon,
    SearchBar,
    SfButton,
    NavMainBar,
    TopMenuLanguageSelector,
    SalesforceWebform,
    Button,
    Hint,
    NavMainBarAllCategoriesButton,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
    componentCaching: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { languageAndCountry, countryLocalization } = useI18n();
    const { toggleCartSidebarWithDelay } = useCartSidebars();
    const { closeCartSidebar } = useCartSidebarStore();
    const { mainSearchIndex } = useAlgolia();
    const { $auth, $cookies, app: { router } } = useVSFContext();
    const { $tagManager, $cia } = useIntegrations();
    const { getAdjustedSlug, currentRoute } = useRouteExtended();
    const { isThankYouPage, isLoginPage } = useIsPage();
    const { toggleNavigation, isNavigationOpen } = useNavigationState();
    const { isAuthenticated } = useUser();
    const { user } = useUserExtended();
    const { callViewHitsEvent, callClickHitEvent } = useAlgoliaGtmEvents();
    const { isMounted } = useIsMounted();
    const { toggleHintVisibilityComputed } = useHint();
    const isBoxVisible = ref(false);
    const toggleHintVisibility = () => {
      toggleHintVisibilityComputed(isBoxVisible);
    };

    const { cart } = useCartExtended();
    const searchPageUrl = getAdjustedSlug(ROUTES.SEARCH);
    const isDropdownOpen = ref(false);
    const isSearchBarOpen = ref(false);
    const { setUrlWithToken } = useMyOrders();
    const cartTotalPrice = computed(() => getTotalGrossPrice(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const isMultiLanguage = computed(() => countryLocalization.value?.isMultiLanguage);
    const accountIcon = computed(() => isAuthenticated.value ? 'profile_fill' : 'profile');
    const toggleSearchBar = () => {
      isSearchBarOpen.value = !isSearchBarOpen.value;
    };
    const isContactFormVisible = ref(false);

    const toggleContactFormVisibility = () => {
      if (isNavigationOpen.value) {
        toggleNavigation();
      }
      isContactFormVisible.value = !isContactFormVisible.value;
    };

    const handleAccountClick = () => {
      hideNavigation();
      if (isAuthenticated.value) {
        isDropdownOpen.value = !isDropdownOpen.value;
        return;
      }
      router.push(getAdjustedSlug(ROUTES.LOGIN));
    };

    watch(() => currentRoute.value?.path, () => {
      closeCartSidebar();
    });

    const hideNavigation = () => {
      if (isNavigationOpen.value) {
        toggleNavigation();
      }
    };

    const handleCartIconClick = function() {
      if (isMounted.value) {
        if (totalItems.value > 0) {
          hideNavigation();
          toggleCartSidebarWithDelay();
        } else {
          toggleHintVisibility();
        }
      }
    };

    const handleLogoClick = () => {
      hideNavigation();
      router.push(getAdjustedSlug(ROUTES.HOME));
    };

    const onProductClicked = ({ product, searchTerm }: {product: HitResultItem, searchTerm: string}) => {
      callClickHitEvent(product, searchTerm);
    };

    onMounted(() => {
      $cia.util.initTracker();

      if (!isAuthenticated.value && $auth?.loggedIn) {
        /** TODO: FIX IT SOMEHOW BY MORE SOPHISTICATED SOLUTION
         * search in auth composable
         * clean state, try force methods logout, login
         */
        window.location.href = window.location.pathname;
      }
      startDatadogUser();
    });

    onSSR(async () => {
      if (!isAuthenticated.value && $auth.loggedIn) {
        const token = $auth.strategy.token.get();
        const cookie = $cookies.get(CT_COOKIE_NAME);
        const provider = $auth.$state.strategy;
        const responseCookie = await createUserCookie(token, cookie, provider);
        $cookies.set(CT_COOKIE_NAME, responseCookie);
      }
    });

    watch(user, () => {
      setUrlWithToken(user.value);
    });

    const closeDropdown = () => {
      isDropdownOpen.value = false;
    };

    const searchExecuted = (currentValue) => {
      if (currentValue !== '') {
        $tagManager.events.triggerSearchResultsTags(currentValue);
      }
    };

    return {
      accountIcon,
      totalItems,
      cartTotalPrice,
      handleAccountClick,
      searchPageUrl,
      isDropdownOpen,
      closeDropdown,
      toggleNavigation,
      isNavigationOpen,
      homePath: getAdjustedSlug(ROUTES.HOME),
      EXPONDO_LOGO_DARK_PATH,
      EXPONDO_LOGO_DARK_SIMPLE_PATH,
      isSearchBarOpen,
      toggleSearchBar,
      handleCartIconClick,
      languageAndCountry,
      mainSearchIndex,
      callViewHitsEvent,
      callClickHitEvent,
      hideNavigation,
      handleLogoClick,
      isMultiLanguage,
      isThankYouPage,
      onProductClicked,
      searchExecuted,
      isContactFormVisible,
      toggleContactFormVisibility,
      toggleHintVisibility,
      isBoxVisible,
      isLoginPage,
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: i18nToLanguageAndCountry(this.$i18n),
        itemscope: 'itemscope',
        itemtype: SCHEMA_ITEM_TYPE.WEB_PAGE,
      },
    };
  },
});

